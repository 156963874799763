import React from 'react'
import img from '../../images/bieznia-vacu.webp'
import {Helmet} from 'react-helmet'

import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import {Fade} from 'react-reveal'
import {Seo} from '../../components/Seo'

const VacuBieznia = () => {
  return (
    <Layout>
      <Seo
        title='Vacu Bieżnia IR Professional | Modelowanie Sylwetki'
        description='VACU BIEŻNIA z IR to urządzenie profesjonalnej bieżni zamkniętej w
        specjalnej kapsule z podciśnieniem i podczerwienią (IR), gdzie zgubisz
        zbędne kilogramy nawet bez zastosowania diety.'
        url='https://modelowaniesylwetki-wawer.pl/strefa-fitness/vacu-bieznia-ir-professional'
      />

      <div className='page-container'>
        <div className='page'>
          <div className='content-1'>
            <h1>VACU BIEŻNIA IR PROFESSIONAL</h1>
            <div className='section'>
              <div className='image-container'>
                <img
                  src={img}
                  width='660px'
                  height='440px'
                  alt='Vacu Bieżnia IR Professional zdjęcie'
                ></img>
              </div>
              <div className='text'>
                <p>
                  VACU BIEŻNIA z IR to urządzenie profesjonalnej bieżni zamkniętej w
                  specjalnej kapsule z podciśnieniem i podczerwienią (IR), gdzie zgubisz
                  zbędne kilogramy nawet bez zastosowania diety. Podczas treningu na VACU
                  BIEŻNI IR dochodzi do spalania tkanki tłuszczowej w dolnych partiach
                  ciała, takich jak: brzuch, biodra, uda, pośladki czy łydki. Promienie
                  podczerwone nagrzewają ciało i rozszerzają naczynia krwionośne czego
                  efektem jest zwiększenie przepływu krwi, lepsze dotlenienie i ukrwienie
                  tkanek. Światło podczerwone nagrzewając skórę powoduje przyspieszenie
                  przemiany materii oraz pomaga wiązać wolne rodniki powodując efektywne
                  oczyszczanie organizmu, usuwanie podskórnej tkanki tłuszczowej i
                  zmniejszenie cellulitu. Przemiana materii zostaje przyspieszona a
                  podciśnienie wpływa korzystnie na układ krwionośny, poprawia krążenie
                  limfy, skutkiem czego skóra staje się wyraźnie wygładzona i jędrniejsza.
                  Zastosowane w urządzeniu podciśnienie powoduje znaczący wzrost spalania
                  tkanki tłuszczowej, poprawę krążenia krwi w tkankach podskórnych oraz
                  poprawę krążenia limfy odpowiedzialnej za usuwanie z organizmu toksyn i
                  nadmiernej ilości płynów.
                </p>
                <div className='cennik'>
                  <span>CENNIK</span>
                  <h2>BIEŻNIA Z PODCIŚNIENIEM</h2>
                  <ul>
                    <li>
                      <p>BIEŻNIA Z PODCIŚNIENIEM - POJEDYNCZY ZABIEG</p>
                      <span className='prices'>30 MIN | 40 zł</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Fade left>
            <Link to='/kontakt'>
              <button>Umów Wizytę</button>
            </Link>
          </Fade>

          {/* <div className='content-1'>
            <div className='fitness-links'>
              <div className='card'>
                <div className='image-container'>
                  <img src={rower}></img>
                </div>
                <div className='text'>
                  <h2>Rower Poziomowy</h2>
                  <p>
                    Rower poziomy doskonale sprawdza się w przypadku osób, które narzekają
                    na bóle pleców. Ustawienia ciała w trakcie ćwiczeń pozwala na
                    odciążenie kręgosłupa co jest szczególnie istotne dla ...
                  </p>
                </div>
                <Link to='/strefa-fitness/rower-poziomowy'>
                  <button>Czytaj Dalej</button>
                </Link>
              </div>

              <div className='card'>
                <div className='image-container'>
                  <img src={rollmasazer}></img>
                </div>
                <div className='text'>
                  <h2>Rollmasażer</h2>
                  <p>
                    ROLLmasażer z IR to nowoczesne urządzenie służące do modelowania
                    sylwetki, redukcji cellulitu i tkanki tłuszczowej oraz do ujędrnienia
                    skóry. Urządzenie to wspomaga odchudzenia, daje ...
                  </p>
                </div>
                <Link to='/strefa-fitness/rollmasazer-z-ir'>
                  <button>Czytaj Dalej</button>
                </Link>
              </div>
              <div className='card'>
                <div className='image-container'>
                  <img src={img}></img>
                </div>
                <div className='text'>
                  <h2>Swan Orbitrek</h2>
                  <p>
                    Urządzenie to łączy w sobie cztery komponenty, a połączenie ich w
                    jednym urządzeniu powoduje, że podczas treningu działamy na całe
                    ciało, gdzie jedno działanie potęguje drugie i uzupełnia kolejne.{' '}
                  </p>
                </div>
                <Link to='/strefa-fitness/swan-orbitrek-ir'>
                  <button>Czytaj Dalej</button>
                </Link>
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </Layout>
  )
}

export default VacuBieznia
